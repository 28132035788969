import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

import Button from 'react-bootstrap/Button'

import Layout from '../components/layout'

export default class notFound extends React.Component {
  render() {
    return (
      <Layout pageTitle="Page not found">
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <h1>Page not found</h1>
        <p className="lead">We couldn't find that page.</p>

        <Button as={Link} to="/" variant="primary m-2">
          Home
        </Button>
        <Button variant="outline-primary m-2" onClick={_ => history.back()}>
          Back
        </Button>
      </Layout>
    )
  }
}
